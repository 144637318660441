import env from '../config/env';
import { Market } from '../interface/market';
import { AuthorizedService } from './authorized.service';

class MarketService extends AuthorizedService {
  public async getMarkets(): Promise<Market[]> {
    const response = await super.get<{ markets: string[] }>('text_semantic_cache/markets');
    return response.markets.map((market, index) => ({
      id: index,
      name: market,
    }));
  }
}

const marketService = new MarketService(env.API_URL);
export default marketService;
