import { Typography } from '@amway/react-components';
import { mdiDatabaseSearch, mdiDelete } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import Button from '../../components/shared/button';
import DatePickerComponent from '../../components/shared/datepicker';
import DropdownBtn, { Props as DropdownBtnProps } from '../../components/shared/dropdown-btn';
import Input from '../../components/shared/input';
import LoadingComponent from '../../components/shared/loading-component';
import PaginatedTableComponent, { Column } from '../../components/shared/paginated-table';
import { Language } from '../../interface/language';
import { Market } from '../../interface/market';
import { SearchRequest } from '../../interface/text-semantic-search';
import languageService from '../../service/language.service';
import marketService from '../../service/market.service';
import textSemanticCacheService from '../../service/text-semantic-cache.service';
import { toISOString } from '../../utils/date-utils';
import './index.scss';

export const columns: Column[] = [
  {
    id: 'market_id',
    label: 'Market ID',
    maxWidth: 15,
  },
  {
    id: 'language',
    label: 'Language',
    maxWidth: 15,
  },
  {
    id: 'user_query',
    label: 'User Query',
    maxWidth: 30,
  },
  {
    id: 'hw_program',
    label: 'Hw Program',
    maxWidth: 30,
  },
  {
    id: 'meal_id',
    label: 'Meal ID',
    maxWidth: 30,
  },
  {
    id: 'response',
    label: 'Response',
    maxWidth: 30,
  },
  {
    id: 'created_at',
    label: 'Created At',
    maxWidth: 15,
  },
  {
    id: 'delete',
    label: 'Delete',
    align: 'right',
    sx: () => ({
      position: 'sticky',
      right: 0,
      backgroundColor: '#fff',
      zIndex: 999,
    }),
  },
];

// Helper function to transform language items to DropdownBtn items format
const makeLanguageItems = (languages: Language[]): DropdownBtnProps['items'] => {
  const collator = new Intl.Collator('en');

  return [
    { label: 'All', id: 'all' },
    ...languages
      .map(language => ({
        label: language.name,
        id: language.id,
      }))
      .sort((a, b) => collator.compare(a.label, b.label)),
  ];
};

const makeMarketItems = (markets: Market[]): DropdownBtnProps['items'] => {
  const collator = new Intl.Collator('en');

  return [
    { label: 'All', id: 'all' },
    ...markets
      .map(market => ({
        label: market.name,
        id: market.id,
      }))
      .sort((a, b) => collator.compare(a.label, b.label)),
  ];
};

export default function SemanticCacheEditComponent() {
  const [selectedLanguage, setSelectedLanguage] = useState<string>();
  const [languages, setLanguages] = useState<Language[]>();
  const [selectedMarket, setSelectedMarket] = useState<string>();
  const [markets, setMarkets] = useState<Market[]>();
  const [foodName, setFoodName] = useState('');
  const [minDate] = useState<string>(new Date('2024-11-01').toISOString()); // TODO - check if would be nice to have a min data being the oldest entry on semantic cache.
  const [maxDate] = useState<string>(toISOString(new Date()));
  const [startDate, setStartDate] = useState<string>(maxDate);
  const [endDate, setEndDate] = useState<string>(maxDate);
  const [cacheContentItems, setCacheContentItems] = useState<any[]>([]);
  const [emptyResponse, setEmptyResponse] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  useEffect(() => {
    async function fetchLanguages() {
      const _languages = await languageService.getLanguages();
      setLanguages(_languages);
    }
    async function fetchMarkets() {
      const _markets = await marketService.getMarkets();
      setMarkets(_markets);
    }
    fetchLanguages();
    fetchMarkets();
  }, []);

  const languageItems = useMemo(() => makeLanguageItems(languages ?? []), [languages]);
  const marketItems = useMemo(() => makeMarketItems(markets ?? []), [markets]);

  const handleLanguageChange: DropdownBtnProps['onClick'] = useCallback(
    item => {
      if (!item || !languages) return;

      if (item.id === 'all') {
        setSelectedLanguage('All');
      } else {
        const selected = languages.find(language => language.id === item.id);
        setSelectedLanguage(selected ? selected.name : undefined);
      }
    },
    [languages],
  );

  const handleMarketChange: DropdownBtnProps['onClick'] = useCallback(
    item => {
      if (!item || !markets) return;

      if (item.id === 'all') {
        setSelectedMarket('All');
      } else {
        const selected = markets.find(market => market.id === item.id);
        setSelectedMarket(selected ? selected.name : undefined);
      }
    },
    [markets],
  );

  const handleCacheSearch = async () => {
    setCacheContentItems([]);
    setLoading(true);

    if (!selectedLanguage || !selectedMarket || !foodName) {
      console.log('missing language, market or food name');
      // TODO: give feedback to the user.
      setLoading(false);
      return;
    }

    const params: SearchRequest = {
      food_name: foodName!,
      start_date: startDate,
      end_date: endDate,
      ...(selectedLanguage !== 'All' && { language: selectedLanguage! }),
      ...(selectedMarket !== 'All' && { market: selectedLanguage! }),
    };

    try {
      const _cacheData = await textSemanticCacheService.search(params);

      if (!_cacheData || _cacheData.length === 0) {
        setEmptyResponse(true);
      } else {
        setEmptyResponse(false);

        // Parse response, add delete icon and actions
        _cacheData.forEach((entry: any) => {
          entry.response = JSON.stringify(entry.response);
          entry.id = entry.semantic_cache_id;
          entry.delete = (
            <span
              onClick={() => handleCacheDelete([entry.semantic_cache_id])}
              style={{ cursor: 'pointer', textAlign: 'center', display: 'block' }}>
              <Icon path={mdiDelete} size={1} color="red" />
            </span>
          );
          entry.__meta = {
            onClick: (row: any) => handleCacheDelete([row.semantic_cache_id]),
          };
        });

        setCacheContentItems(_cacheData);
      }
    } catch (error) {
      console.error('Error during cache search:', error);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Handles the deletion of N selected cache entries.
   */
  const handleCacheDelete = async (ids: any[]) => {
    setLoading(true);
    const response = await textSemanticCacheService.deleteEntries(ids);
    setLoading(false);

    if (response.status_code === 200) {
      setCacheContentItems(prevItems => prevItems.filter(item => !ids.includes(item.semantic_cache_id)));
    } else {
      console.error(`Failed to delete item with IDs ${ids}`);
    }
  };

  return (
    <Container className="semantic-cache-edit">
      <Card>
        <Card.Body>
          <Typography variant="heading">Semantic Cache Edit</Typography>

          <section className="cache-filter-container">
            <DropdownBtn
              id="language-selector"
              className="language-selector"
              variant="secondary"
              value={selectedLanguage}
              items={languageItems}
              label="Languages"
              placeholder="Select a language"
              onClick={handleLanguageChange}
            />

            <DropdownBtn
              id="market-selector"
              className="market-selector"
              variant="secondary"
              value={selectedMarket}
              items={marketItems}
              label="Markets"
              placeholder="Select a market"
              onClick={handleMarketChange}
            />

            <Input
              title="Food Name"
              required
              placeholder="Food name to be searched"
              className="food-name-input"
              value={foodName}
              onChange={e => setFoodName(e.target.value)}
            />

            <DatePickerComponent
              id="startDate"
              className="date-picker"
              label="Start date"
              onChange={setStartDate}
              value={startDate}
              minDate={minDate}
              maxDate={endDate}
            />
            <DatePickerComponent
              id="endDate"
              className="date-picker"
              label="End date"
              onChange={setEndDate}
              value={endDate}
              minDate={startDate}
              maxDate={maxDate}
            />

            <Button variant="secondary" className="search-btn" onClick={handleCacheSearch}>
              Search
              <Icon path={mdiDatabaseSearch} size={0.8} style={{ marginLeft: '5px' }} />
            </Button>
          </section>
        </Card.Body>
      </Card>
      {(cacheContentItems.length > 0 || emptyResponse || loading) && (
        <Card>
          <Card.Body>
            {loading ? (
              <div className="loading-div">
                <LoadingComponent className="ms-2 spinner" />
              </div>
            ) : (
              <>
                <Row>
                  <Col>
                    <Card.Title>{emptyResponse ? 'No cache entries found' : 'Preview'}</Card.Title>
                  </Col>
                </Row>
                {!emptyResponse && (
                  <Row>
                    <Col className="justify-content-center">
                      <PaginatedTableComponent
                        columns={columns}
                        rows={cacheContentItems}
                        rowIdPropName="semantic_cache_id"
                        selectableRows={true}
                        setSelectedRows={setSelectedRows}
                        handleDeleteSelected={handleCacheDelete}
                        // sx={{ maxWidth: 'calc(100vw - 307px - 2*24px - 32px - 38px - 19px)' }}
                      />
                    </Col>
                  </Row>
                )}
              </>
            )}
          </Card.Body>
        </Card>
      )}
    </Container>
  );
}
