import env from '../config/env';
import { Language } from '../interface/language';
import { AuthorizedService } from './authorized.service';

class LanguageService extends AuthorizedService {
  public async getLanguages(): Promise<Language[]> {
    const response = await super.get<{ languages: string[] }>('text_semantic_cache/languages');
    return response.languages.map((language, index) => ({
      id: index,
      name: language,
    }));
  }
}

const languageService = new LanguageService(env.API_URL);
export default languageService;
