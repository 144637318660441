import React, { ReactNode } from 'react';
import './index.scss';

interface Props {
  onClick?: VoidFunction;
  disabled?: boolean;
  type?: 'button' | 'submit';
  variant?: 'primary' | 'secondary' | 'tertiary' | 'quaternary';
  size?: 'md' | 'sm';
  className?: string;
  children?: ReactNode;
}

const Button: React.FC<Props> = props => {
  const { children, variant = 'primary', size = 'md', className = '' } = props;

  return (
    <button {...props} className={`our-btn ${variant} ${size} ${className}`}>
      {children}
    </button>
  );
};

export default Button;
