import { Tooltip as MuiTooltip, TooltipProps } from '@mui/material';
import React, { useEffect, useState } from 'react';

export interface Props {
  placement?: TooltipProps['placement'];
  title: TooltipProps['title'];
  disabled?: boolean;
  children: React.ReactNode;
}

const Tooltip: React.FC<Props> = props => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { title, placement, disabled } = props;
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (disabled) setIsOpen(false);
  }, [disabled]);

  return (
    <MuiTooltip
      open={isOpen}
      onOpen={() => !disabled && setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      title={title}
      arrow
      placement={placement}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: 'var(--text-black)',
            padding: '8px',
            borderRadius: '10px',
            boxShadow: 'var(--box-shadow-small)',
          },
        },
        arrow: {
          sx: {
            color: 'var(--text-black)',
          },
        },
      }}>
      {props.children as any}
    </MuiTooltip>
  );
};

export default Tooltip;
