import env from '../config/env';
import { SearchRequest } from '../interface/text-semantic-search';
import { AuthorizedService } from './authorized.service';

class TextSemanticCacheService extends AuthorizedService {
  public search(params: SearchRequest): Promise<any[]> {
    return super.get<any[]>('text_semantic_cache/search', {
      params: params,
    });
  }

  public deleteEntries(id: number[]): Promise<any> {
    return super.delete<any>('text_semantic_cache/delete', {
      data: { id_list: id },
    });
  }
}

const textSemanticCacheService = new TextSemanticCacheService(env.API_URL);
export default textSemanticCacheService;
