import { Environments } from '../@types/configuration';

export interface EnvironmentVariables {
  ADMIN_API_URL: string;
  API_URL: string;
  ENV: Environments;
  OKTA_ISSUER: string;
  OKTA_CLIENT_ID: string;
  OKTA_SCOPES: string[];
}

const environment: EnvironmentVariables = Object.seal({
  ADMIN_API_URL: process.env.REACT_APP_ADMIN_API_URL!,
  API_URL: process.env.REACT_APP_API_URL!,
  ENV: process.env.REACT_APP_ENV as Environments,
  OKTA_ISSUER: process.env.REACT_APP_OKTA_ISSUER!,
  OKTA_CLIENT_ID: process.env.REACT_APP_OKTA_CLIENT_ID!,
  OKTA_SCOPES: process.env.REACT_APP_OKTA_SCOPES!.split(','),
});

export const appTitle = 'Meal Plate Analyser';

export default environment;
