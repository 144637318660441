import { AuthState, CustomUserClaims, OktaAuth, UserClaims } from '@okta/okta-auth-js';
import { AxiosRequestConfig } from 'axios';
import environment from '../../config/env';
import { UserRole } from '../../interface/user';
import { AuthorizedService } from '../../service/authorized.service';

// We may refactor and remove this
class AuthService extends AuthorizedService {
  private accessToken?: string | null;
  private idToken?: string | null;
  private config?: AxiosRequestConfig | null;
  private role?: UserRole | null;
  private nonce?: string | null;
  private user?: UserClaims<CustomUserClaims> | null;
  public userStored: boolean = false;

  get requestConfig(): AxiosRequestConfig | undefined | null {
    return this.config;
  }

  set oktaAuthState(oktaAuthState: AuthState | undefined | null) {
    if (oktaAuthState?.isAuthenticated) {
      this.accessToken = oktaAuthState.accessToken?.accessToken;
      this.idToken = oktaAuthState.idToken?.idToken;
      this.nonce = oktaAuthState.idToken?.claims.nonce;
      this.config = {
        headers: {
          Authorization: 'Bearer ' + this.accessToken,
        },
      };
    } else {
      this.accessToken = null;
      this.idToken = null;
      this.nonce = null;
      this.config = {};
      this.role = null;
      this.clearSession();
    }
  }

  public getLoggedUser(oktaAuth?: OktaAuth): Promise<UserClaims<CustomUserClaims> | undefined | null> {
    let user: UserClaims<CustomUserClaims> | undefined | null;

    if (this.user) {
      user = this.user;
    } else {
      user = this.getStoredSession();

      if (user) {
        this.user = user;
      } else if (oktaAuth) {
        return oktaAuth.token.getUserInfo().then(u => {
          this.user = u;
          return u;
        });
      }
    }

    return Promise.resolve(user);
  }

  public storeUser(nativeId?: String, email?: String, fullName?: String): Promise<void> {
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: this.config?.headers?.Authorization as string,
        id_token: this.idToken as string,
        nonce: this.nonce as string,
      },
    };
    return super.post('user/storeUser', config, { params: { nativeId, email, fullName } });
  }

  private getStoredSession(): UserClaims<CustomUserClaims> | undefined | null {
    const user = localStorage.getItem('user');

    return user ? JSON.parse(user) : null;
  }

  private storeSession(user?: UserClaims<CustomUserClaims> | null): void {
    localStorage.setItem('user', JSON.stringify(user));
  }

  private clearSession(): void {
    localStorage.removeItem('user');
  }
}

export default new AuthService(environment.ADMIN_API_URL);
