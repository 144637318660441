import { Card, Container } from 'react-bootstrap';
import ComingSoonComponent from '../coming-soon';
import './index.scss';

export default function TestingToolComponent() {
  return (
    <Container className="testing-tool">
      <Card>
        <Card.Body>
          <ComingSoonComponent />
        </Card.Body>
      </Card>
    </Container>
  );
}
