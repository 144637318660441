import { SidebarNav } from '@amway/react-components';
import { useMemo } from 'react';
import { MdFlashOn, MdOutlineSpeed } from 'react-icons/md';
import { RiDatabase2Fill, RiTestTubeFill } from 'react-icons/ri';
import { authenticatedRoutes } from '../../../config/routes';
import { FeaturesCtxType } from '../../../resources/features/features-context';
import useFeatures from '../../../resources/features/features-hook';

type Props = {
  open: boolean;
  toggleMenu: VoidFunction;
  onLogout: () => void;
};

// Custom cache icon component
const CacheEditIcon = () => (
  <div style={{ position: 'relative', display: 'inline-block', fontSize: '24px' }}>
    <RiDatabase2Fill style={{ verticalAlign: 'baseline' }} />
    <MdFlashOn
      style={{
        verticalAlign: 'baseline',
        position: 'absolute',
        top: '8px',
        right: '2px',
        color: 'black',
        fontSize: '16px',
      }}
    />
  </div>
);

const links = [
  {
    to: '/dashboard',
    label: 'Dashboard',
    eventKey: 'dashboard',
    IconComponent: MdOutlineSpeed,
  },

  {
    to: '/testing-tool',
    label: 'Testing Tool',
    eventKey: 'testing-tool',
    IconComponent: RiTestTubeFill,
  },

  {
    to: '/semantic-cache-edit',
    label: 'Semantic Cache Edit',
    eventKey: 'semantic-cache-edit',
    IconComponent: CacheEditIcon,
  },
];

const filterAllowedLinks = (hasFeature: FeaturesCtxType['hasFeature']) => {
  return links.filter(link => {
    const linkRoute = authenticatedRoutes.find(route => route.path === link.to)!;

    return hasFeature(linkRoute.feature);
  });
};

export default function SideNavComponent({ onLogout, toggleMenu, open }: Props) {
  const { hasFeature } = useFeatures();
  const allowedLinks = useMemo(() => filterAllowedLinks(hasFeature), [hasFeature]);

  return <SidebarNav open={open} onLogout={onLogout} toggleMenu={toggleMenu} allowedLinks={allowedLinks} />;
}
