import { Typography } from '@amway/react-components';
import { Card, Col, Container, Row } from 'react-bootstrap';
import './index.scss';

export default function DashboardComponent() {
  return (
    <Container className="dashboard">
      <Card>
        <Card.Body>
          <Row>
            <Col>
              <Typography variant="heading">Dashboard</Typography>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
}
