import { Card, Container, Image } from 'react-bootstrap';
import './index.scss';

export default function ComingSoonComponent() {
  return (
    <Container className="coming-soon">
      <Card>
        <Card.Body>
          <Image src="assets/coming-soon-logo.svg" width="160" height="160" />
          <h1>Coming Soon</h1>
          <h4>We will celebrate the launch of a new page very soon!</h4>
        </Card.Body>
      </Card>
    </Container>
  );
}
