import { BrowserRouter } from 'react-router-dom';
import AuthProvider from './resources/auth/auth-provider';
import FeaturesProvider from './resources/features/features-provider';
import Router from './router';

export default function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <FeaturesProvider>
          <Router />
        </FeaturesProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}
